import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function IntellectualPropertyDispute() {
  /* Slider */
  // const disputeSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Intellectual Property Dispute`,
  //     content: `Secure Intellectual Property Dispute for Your Products and Brands`,
  //     image: "/imgs/registration/ngo/80g-registration.png",
  //     alt_tag: "Intellectual Property Dispute Near Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Intellectual Property Dispute`,
    buyBtnLink: `#pricing-buy`,
    price: `24999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const disputeAboutData = {
    header: `Intellectual Property Dispute`,
    sub_title: `Infringement is the most commonly occurring intellectual property dispute in India.`,
    content_paragraph: [
      `The phrase "intellectual property" refers to the expressions of the mind, creative effort, scholarly work, 
      names and images, as well as development, image, and configuration used in business or company advancement.
      The IP framework seeks to create a setting where innovation and creativity can flourish by establishing 
      some type of harmony between the interests of innovators and the larger public interest.`,
    ],
  };

  /* Scroll Nav Data */
  const disputeScrollId = [
    {
      id: `#regulations`,
      heading: `Regulations`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#acts`,
      heading: `Acts`,
    },
    {
      id: `#property-types`,
      heading: `Property Types`,
    },
  ];

  /* Img Content Component Data */
  const disputeIcData = {
    id: 'regulations',
    mt_div: '',
    mt_img: '',
    header: 'Regulations For Disputes Involving Intellectual Property',
    image: '/imgs/registration/ipr/td9.png',
    alt_tag: 'Disputes Intellectual Property',
    points: [
      {
        head: ``,
        content: `It is possible to issue stop and desist orders in the rare case that a trademark is 
        utilized without the required legal authorization.`,
        icon: true,
      },
      {
        content: ` Quit using infringement as an excuse. your business could occasionally suffer from intellectual 
        property issues, and if you're an individual you might have to lose a lot of money.`,
        icon: true,
      },
      {
        head: '',
        content: `You made a wise decision by consulting a lawyer to settle your intellectual property conflict.`,
        icon: true,
      },
      {
        head: '',
        content: `The legal advisor will present your case regarding the infringement issue, manage you during
         the full proceeding, and also give you legal advice.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const disputeCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Intellectual Property Dispute Procedure',
    content: `Intellectual property refers to any creative effort, practical design, logo, geological symbol, 
    or other innovative manifestations that allow a company to profit financially.Two types of intellectual 
    property are`,
    body: [
      {
        head: ``,
        points: `Tangible`,
        icon: true,
      },
      {
        head: ``,
        points: `Intangible`,
        icon: true,
      },
      {
        head: ``,
        points: `Each creator has the right to protect their work from comparison. The creator can ensure their
         plans and excellent work by registering their copyrights, trademarks, and patents. Whether the creator is
          a person or a company, it is wise to consult a professional like TODAYFILINGS in matters pertaining to intellectual 
          property disputes.`,
        icon: true,
      },
      {
        head: `Step-by-step Guide for Registering Intellectual Property`,
        points: `Registration of IPR.`,
        icon: true,
      },

      {
        points: `Completing the application.`,
        icon: true,
      },
      {
        points: `The registration's resistance.`,
        icon: true,
      },
      {
        points: `Being distributed in the IPR journal.`,
        icon: true,
      },
      {
        points: `Analyzing and examining the basis.`,
        icon: true,
      },
      {
        points: `Communication of objections through a show-cause notice.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const disputeCiData = {
    id: 'property-types',
    background: 'main-content',
    mt_div: '',
    header: 'Various Types Of Intellectual Property',
    paragraph: ``,
    points: [
      {
        head: `Intellectual property can consist of many types of intangibles, and some of the most common are listed below:`,
        content: `Patent.`,
        icon: true,
      },
      {
        content: `Copyright.`,
        icon: true,
      },
      {
        content: `Trademark.`,
        icon: true,
      },
      {
        content: `Business Secrets.`,
        icon: true,
      },
      {
        content: `Commercial Designs.`,
        icon: true,
      },
      {
        content: `Location-Based Information.`,
        icon: true,
      },
    ],
    image: '/imgs/business/sliders/nidhi-slider.png',
    alt_tag: 'Intellectual property dispute',
  };

  /* ImgSlider Data */
  const disputeImages = [
    '/imgs/registration/ipr/td7.png',
    '/imgs/registration/ipr/td3.png',
    '/imgs/registration/ipr/td4.png',
  ];
  /*  Slider Content Component Data */
  const disputeSCData = {
    id: 'acts',
    background: [],
    mt_div: '',
    header: 'Intellectual Property Rights',
    paragraph: `Intangible property created by the mind is known as intellectual property. Certain rights are 
    granted to the inventor to safeguard his property in order to protect this property. Intellectual property 
    rights are the name given to these rights. Acts to safeguard both private and corporate intellectual property
     have been passed into law in India.`,
    content: [
      {
        points: `Copyright Act, 1957`,
        icon: true,
      },
      {
        points: `The Patent Act, 1978`,
        icon: true,
      },
      {
        points: `Trademark Act, 1999`,
        icon: true,
      },
      {
        points: `Geographical Indication of Goods (Registration and Protection) Act, 1999.`,
        icon: true,
      },
    ],
    images: disputeImages,
  };

  var FAQ_data = [
    {
      header: 'What exactly is intellectual property?',
      body: [
        {
          content: `Intellectual property is a scarce resource that encompasses a
          variety of rights that govern the possession, sale, and use of any
          item created by using intellect or creativity, such as instrumental
          composition, logo design, literary creation, and machines.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Can I register my trademark? ',
      body: [
        {
          content: `Yes. You can register your trademark at the Intellectual Property Office. This will imply that 
          you have elite use to your imprint for the products and ventures indicated in your application.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How to protect your Intellectual Property from Infringement? ',
      body: [
        {
          content: `After hustling hard to develop your Intellectual Property (IP), you will need to shield it from 
          others profiting by it without your authorization. The most grounded security originates from enrolling 
          your work. Thusly, you put your case into the general visibility, demoralizing many (however not all) 
          individuals from utilizing your work without consent.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the issues with Intellectual Property? ',
      body: [
        {
          content: `Intellectual property includes copyrights, trademarks, patents and trade secrets. Violations 
          could adversely affect your business and even lead to criminal charges and jail time. Avoiding intellectual
           property violations is a matter of due diligence and best practices around the use of third-party content.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Who are responsible for administration of IPRs in the country? ',
      body: [
        {
          content: `Patents, designs, trademarks and geographical indications are administered by the Controller 
          General of Patents, Designs and Trademarks which is under the control of the Department of Industrial 
          Policy and Promotion, Ministry of Commerce and Industry. Copyright is under the charge of the Ministry 
          of Human Resource Development. The Act on Layout-Design of Integrated Circuits is administered by the 
          Ministry of Telecommunication and Information Technology. Protection of Plant Varieties and Farmers’ 
          Rights Authority, Ministry of Agriculture administers the Act on Plant variety.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Are intellectual property rights regional in nature?',
      body: [
        {
          content: `Yes, intellectual property rights are territorial. It implies that
          an Indian registration is only relevant in India. To confirm
          Intellectual Property in another country, one must search for
          assurance independently under the relevant law.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Are IP rights territorial in nature? ',
      body: [
        {
          content: `Yes, IP rights are regional. It implies that an Indian registration is substantial just in India. 
          For assurance of Intellectual Property in some other nation, one needs to look for protection separately under
           the important law.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Do i own my own Intellectual Property?',
      body: [
        {
          content: `The most prevalent sort of intellectual property issue is
          infringement. Infringement happens when someone uses intellectual
          property without first seeking permission from the property's owner.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the concerns about intellectual property?',
      body: [
        {
          content: `Trademarks, licenses, copyrights, and trade secrets are all examples
          of intellectual property. Infringement could have a negative impact
          on your business and could result in charges and prison time.
          Avoiding intellectual property infringement necessitates careful
          consideration and best practices when utilizing third-party content.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is it possible for me to register my trademark?`,
      body: [
        {
          content: `Yes. Your trademark can be registered with the Intellectual Property
          Office. This implies that you have made excellent use of your
          engraving for the products and adventures depicted in your program.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Intellectual Property Dispute'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={disputeSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={disputeAboutData} />
          <Counter />
          <ScrollNav scroll_data={disputeScrollId} />
          <ImgContent item={disputeIcData} />
          <ContentForm CFSection_data={disputeCfData} />
          <SliderContent ScSection_data={disputeSCData} />
          <ContentImg CISection_data={disputeCiData} />

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
